import { MutationConfig, QueryConfig, useMutation, useQuery } from 'react-query';

import { cacheableApi } from 'api';
import { Errors } from 'api/checkout/checkout';
import { ParticipantClipInfoNextJsModel } from 'models/portal.model';
import { CacheManager } from 'utils/cache.manager';

import {
  GetParticipantClipInfoPros,
  UpdateIsLinkedInSignUpClickProps,
  UpdateIsViewsClipProps,
  UpdateReactionClickProps,
} from './fetchParticipant.types';

const fetchParticipant = async (
  cache: CacheManager,
  domain: string,
  clipPageUri: string,
): Promise<ParticipantClipInfoNextJsModel> =>
  cacheableApi(cache, domain, `/clips?permalink=${domain}/clip/${clipPageUri}`);

const fetchParticipantData = {
  fetchParticipant,
};

export default fetchParticipantData;

const getParticipantClipInfo = ({ clippingPlatformClient, studentDomain }: GetParticipantClipInfoPros) =>
  clippingPlatformClient
    .get<ParticipantClipInfoNextJsModel>(`/clips?permalink=${studentDomain}`)
    .then(({ data }) => data);

export const useGetParticipantClipInfo = ({
  clippingPlatformClient,
  studentDomain,
  options,
}: GetParticipantClipInfoPros & {
  options?: QueryConfig<ParticipantClipInfoNextJsModel, unknown> | undefined;
}) =>
  useQuery(
    ['getRetailDetails', studentDomain],
    () => getParticipantClipInfo({ clippingPlatformClient, studentDomain }),
    {
      ...options,
    },
  );

export const updateIsViewsClip = ({ clippingPlatformClient, participantId, body }: UpdateIsViewsClipProps) =>
  clippingPlatformClient
    .patch(`/clips/participant/${participantId}`, body)
    .then(({ data }) => data)
    .catch((data) => data);

export const updateIsLinkedInSignUpClick = ({
  clippingPlatformClient,
  participantId,
  body,
}: UpdateIsLinkedInSignUpClickProps) =>
  clippingPlatformClient
    .patch(`/clips/participant/linkedin/${participantId}`, body)
    .then(({ data }) => data)
    .catch((data) => data);

export const useUpdateIsViewsClip = (
  options?: MutationConfig<unknown, Errors<unknown>, Pick<UpdateIsViewsClipProps, 'body'>, unknown>,
) => {
  return useMutation(
    ({ clippingPlatformClient, participantId, body }: UpdateIsViewsClipProps) =>
      updateIsViewsClip({ clippingPlatformClient, participantId, body }),
    options,
  );
};

export const updateParticipantReaction = ({ clippingPlatformClient, participantId, body }: UpdateReactionClickProps) =>
  clippingPlatformClient
    .patch(`/clips/participant/reaction/${participantId}`, body)
    .then(({ data }) => data)
    .catch((data) => data);
