import { useQuery } from 'react-query';

import { fetchSortedClips } from 'api/sortedClips/fetchSortedClips';
import { MostRecent } from 'homePage/tabs/mostRecent/MostRecent';
import { MostRecentContainerProps } from 'homePage/tabs/mostRecent/MostRecent.types';

export const MostRecentContainer = ({ domain }: MostRecentContainerProps) => {
  const sort = 'recent';

  const { data } = useQuery(['fetchSortedClips', { sort }], (key, { sort }) =>
    fetchSortedClips({
      sort: sort,
      domain,
    }),
  );

  return <MostRecent data={data} />;
};
