import { sendResponcesSurveyApi } from 'api';

const sendSurveyResponce = async (responce: any, participantId: string) =>
  sendResponcesSurveyApi(responce, participantId);

const sendSurveyResponceData = {
  sendSurveyResponce,
};

export default sendSurveyResponceData;
