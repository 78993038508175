import clsx from 'clsx';

import { MostRecentProps } from 'homePage/tabs/mostRecent/MostRecent.types';
import { SortedClip } from 'models/portal.model';
import { ClipCard } from 'shared/clipCard/ClipCard';

import classes from './MostRecent.module.scss';

export const MostRecent = ({ data }: MostRecentProps) => (
  <div className={clsx('row', 'row-gutter', classes.mostRecentWrapper)}>
    {data &&
      data?.map(({ clipPageUri, thumbnailUri, fullName, subtitle, clipGifUri }: SortedClip) => (
        <div
          key={fullName}
          className={clsx('col-gutter', 'col-12', 'col-tablet-sm-6', 'col-desktop-sm-4', classes.col)}
        >
          <ClipCard
            clipPageUri={clipPageUri}
            fullName={fullName}
            subtitle={subtitle}
            thumbnailUri={thumbnailUri}
            clipGifUri={clipGifUri}
            socialMediaMessage="Share on social media"
          />
        </div>
      ))}
    {/*<div className={classes.buttonWrapper}>*/}
    {/*  <Button url="/">Show More</Button>*/}
    {/*</div>*/}
  </div>
);
