import { format } from 'date-fns';

import classes from './Slide.module.scss';
import { SlideProps } from './Slide.types';

export const Slide = ({ title, date, image, url, color }: SlideProps) => {
  return (
    <a href={url} className={classes.slide}>
      <div className={classes.image} style={image ? { backgroundImage: `url(${image})` } : { background: color }} />
      <div className={classes.title}>
        {title} — {format(new Date(date), 'LLLL yyyy')}
      </div>
    </a>
  );
};
