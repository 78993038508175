import { sendSessionResponcesSurveyApi } from 'api';

const sendSurveySessionResponce = async (responce: any, participantId: string) =>
  sendSessionResponcesSurveyApi(responce, participantId);

const sendSurveySessionResponceData = {
  sendSurveySessionResponce,
};

export default sendSurveySessionResponceData;
