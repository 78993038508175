import React from 'react';

import fetchCeremonyData from 'api/ceremony/fetchCeremony';
import fetchParticipantData from 'api/participant/fetchParticipant';
import { fetchCeremonies } from 'api/portal/ceremonies/fetchCeremonies';
import fetchPortalData from 'api/portal/fetchPortal';
import { getMerchandise } from 'api/retail/retail';
import fetchSurveyData from 'api/survey/fetchSurvey';
import sendSurveyResponce from 'api/survey/sendSurveyResponce';
import sendSurveySessionResponceData from 'api/survey/sendSurveySessions';
import { MyGetServerSidePropsContext, ResLocalsProps } from 'server/types';
import { SiteLayout as PageLayout } from 'shared';
import { SiteLayoutProps } from 'shared/siteLayout/SiteLayout.types';

const validDomain = (domain: string): boolean => new RegExp(`\\.${process.env.NEXT_STAGECLIP_DOMAIN}$`).test(domain);

export const getPortalProps = async (ctx: MyGetServerSidePropsContext) => {
  const { cache, domain } = ctx.res.locals as ResLocalsProps;

  if (!validDomain(domain)) {
    throw new Error(`Invalid portal domain "${domain}"`);
  }

  return await fetchPortalData.fetchPortal(cache, domain);
};

export type GetParticipantDataParams = {
  clipPageUri: string[];
};
export type GetParticipantDataOnCheckoutParams = {
  checkoutPageUri: string[];
};

export type GetCeremonyDataParams = {
  ceremonyPageUri: string[];
};

export type GetSurveyDataParams = {
  participantId: string[];
};

export const getParticipantData = async (ctx: MyGetServerSidePropsContext<GetParticipantDataParams>) => {
  const clipPageUri = (ctx.params?.clipPageUri || []).join('/');
  const { cache, domain } = ctx.res.locals as ResLocalsProps;

  return await fetchParticipantData.fetchParticipant(cache, domain, clipPageUri);
};

export const getParticipantDataOnCheckout = async (
  ctx: MyGetServerSidePropsContext<GetParticipantDataOnCheckoutParams>,
) => {
  const clipPageUri = (ctx.params?.checkoutPageUri || []).join('/');
  const { cache, domain } = ctx.res.locals as ResLocalsProps;

  return await fetchParticipantData.fetchParticipant(cache, domain, clipPageUri);
};

export const getCeremonyData = async (ctx: MyGetServerSidePropsContext<GetCeremonyDataParams>) => {
  const ceremonyPageUri = (ctx.params?.ceremonyPageUri || []).join('/');
  const { cache, domain } = ctx.res.locals as ResLocalsProps;

  return await fetchCeremonyData.fetchCeremony(cache, domain, ceremonyPageUri);
};

export const SiteLayout = <T extends SiteLayoutProps>({
  children,
  data: {
    logoImageUrl,
    logoBackgroundColor,
    color,
    domain,
    clipSearchEnabled,
    clip,
    isAllowCollctiv,
    config,
    isAllowForAmazonMusic,
    isSignUpToAmazonMusic,
  },
}: {
  children: React.ReactElement;
  data: T;
}) =>
  PageLayout({
    children,
    logoImageUrl,
    logoBackgroundColor,
    color,
    domain,
    clipSearchEnabled,
    clip,
    isAllowCollctiv,
    isAllowForAmazonMusic,
    isSignUpToAmazonMusic,
    config,
  });

export const getCeremonies = async (ctx: MyGetServerSidePropsContext) => {
  const { cache, domain } = ctx.res.locals;

  return await fetchCeremonies(cache, domain);
};

export const getSurveyData = async (ctx: MyGetServerSidePropsContext, participantId: string) => {
  const { cache } = ctx.res.locals;
  const data = await fetchSurveyData.fetchSurvey(cache, participantId);

  return data;
};

export const getMerchandiseData = async (institutionId: string) => {
  const data = await getMerchandise({ institutionId });

  return JSON.stringify(data);
};

export const sendSurveyResponceData = async (responces: any, participantId: string) => {
  const data = await sendSurveyResponce.sendSurveyResponce(responces, participantId);

  return JSON.stringify(data);
};

export const sendPartialSurveyResponceData = async (responces: any, participantId: string) => {
  const data = await sendSurveySessionResponceData.sendSurveySessionResponce(responces, participantId);

  return JSON.stringify(data);
};
