import videoParser from 'js-video-url-parser';
import { YouTubeVideoInfo } from 'js-video-url-parser/lib/provider/youtube';

export const getYoutubePlaylistLink = (youTubeLink: string) => {
  const parsedYouTubeLink = videoParser.parse(youTubeLink) as YouTubeVideoInfo;

  const link = `https://www.youtube.com/embed/videoseries?list=${parsedYouTubeLink.list}`;

  return link;
};
