import clsx from 'clsx';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { VirtualGraduationSection, CeremoniesCarousel } from 'homePage';
import { SearchContainer } from 'homePage/search/SearchContainer';
import { MostRecentContainer } from 'homePage/tabs/mostRecent/MostRecentContainer';
import { Button } from 'shared/button/Button';
import { ClipCard } from 'shared/clipCard/ClipCard';
import { Logo } from 'shared/logo/Logo';
import { useWindowSize } from 'utils/hooks/useWindowResize';

import classes from './HomePage.module.scss';
import { HomePageProps } from './HomePage.types';
import { isInstitutionHaveDisableRecentClips } from './HomePage.utils';

export const HomePage = ({
  portalColor,
  domain,
  virtualGraduationSectionToggle,
  sectionVirtualGraduation,
  logoImageUrl,
  clipSearchEnabled,
  ceremonies,
  institutionId,
  institutionName,
}: HomePageProps) => {
  const [windowWidth] = useWindowSize();
  const [isBelowTablet, setIsBelowTablet] = useState(windowWidth < 600);
  const [countOfVisibleCeremonies, setCountOfVisibleCeremonies] = useState(3);
  useEffect(() => {
    setIsBelowTablet(window?.innerWidth < 600);
  }, [windowWidth]);

  return (
    <div
      className={clsx(
        !isInstitutionHaveDisableRecentClips(institutionId)
          ? classes.homePageWrapper
          : classes.homePageWrapperWithBottomSpace,
      )}
    >
      <div className={'fullColumn'}>
        {logoImageUrl && <Logo logoUrl={logoImageUrl} institutionName={institutionName ? institutionName : ''} />}
      </div>

      <div className={clsx('fullColumn', classes.wrapper, classes.searchWrapper)}>
        {clipSearchEnabled && (
          <div className="col-12 col-desktop-sm-7">
            <SearchContainer domain={domain} color={portalColor} isAllowCollctiv={false} />
          </div>
        )}
      </div>

      {!isBelowTablet && !!ceremonies?.length && (
        <>
          <div className={clsx('fullColumn', classes.wrapper)}>
            <div className="col-12 col-desktop-sm-7">
              <CeremoniesCarousel ceremonies={ceremonies} color={portalColor} />
            </div>
          </div>
        </>
      )}

      {isBelowTablet && !!ceremonies?.length && (
        <div className="fullColumn">
          {ceremonies
            .slice(0, countOfVisibleCeremonies)
            .map(({ fullName, date, ceremonyPageUri, thumbnailUrl }, index) => (
              <ClipCard
                key={`${fullName}${index}`}
                clipPageUri={ceremonyPageUri}
                fullName={`${fullName} — ${format(new Date(date), 'LLLL yyyy')}`}
                thumbnailUri={thumbnailUrl}
                className={classes.ceremonyCard}
              />
            ))}
          {countOfVisibleCeremonies <= ceremonies.length && (
            <div className={classes.buttonWrapper}>
              <Button onClick={() => setCountOfVisibleCeremonies((prevState) => prevState + 3)}>Show More</Button>
            </div>
          )}
        </div>
      )}

      {virtualGraduationSectionToggle && sectionVirtualGraduation && (
        <div className={classes.virtualGraduationSectionWrapper} style={{ backgroundColor: portalColor }}>
          <VirtualGraduationSection
            virtualGraduationMessage={sectionVirtualGraduation.virtualGraduationMessage}
            virtualGraduationVideo={sectionVirtualGraduation.virtualGraduationVideo}
          />
        </div>
      )}
      {clipSearchEnabled && !isInstitutionHaveDisableRecentClips(institutionId) && (
        <>
          <div className={classes.tabsHeader}>
            <h2>Most recent clips</h2>
          </div>
          {/*<Tabs tabs={TABS} />*/}
          <div className="fullColumn">
            <MostRecentContainer domain={domain} />
          </div>
        </>
      )}
    </div>
  );
};
