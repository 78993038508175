import parse from 'html-react-parser';
import React from 'react';

import { Lazy } from 'shared/lazy/Lazy';

import { EmbedVideo } from './EmbedVideo/EmbedVideo';
import styles from './VirtualGraduationSection.module.scss';
import { VirtualGraduationSectionProps } from './VirtualGraduationSection.types';
import { getYoutubePlaylistLink } from './VirtualGraduationSection.utils';

export const VirtualGraduationSection = ({
  virtualGraduationMessage,
  virtualGraduationVideo,
}: VirtualGraduationSectionProps) => (
  <>
    <div className={styles.container}>{parse(virtualGraduationMessage)}</div>
    {virtualGraduationVideo && (
      <Lazy>
        {(visible) =>
          visible ? (
            <EmbedVideo>
              <iframe
                title="Virtual Graduation Promo Video"
                src={getYoutubePlaylistLink(virtualGraduationVideo)}
                frameBorder="0"
                allowFullScreen
                loading="lazy"
              />
            </EmbedVideo>
          ) : (
            <div className={styles.videoPlaceHolder} />
          )
        }
      </Lazy>
    )}
  </>
);
