import { cacheableApi } from 'api';
import { CeremonyClipInfoNextJsModel } from 'models/portal.model';
import { CacheManager } from 'utils/cache.manager';

const fetchCeremony = async (
  cache: CacheManager,
  domain: string,
  ceremonyPageUri: string,
): Promise<CeremonyClipInfoNextJsModel> =>
  cacheableApi(cache, domain, `/ceremonies?permalink=${domain}/ceremony/${ceremonyPageUri}`);

const fetchCeremonyData = {
  fetchCeremony,
};

export default fetchCeremonyData;
