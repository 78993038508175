import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import dynamic from 'next/dist/next-server/lib/dynamic';
import React from 'react';

import { ShareDropMenuProps } from 'homePage/shareDropMenu/ShareDropMenu.types';
import { ClipCardProps } from 'shared/clipCard/ClipCard.types';

import classes from './ClipCard.module.scss';

const ShareDropMenu = dynamic<ShareDropMenuProps>(
  () => import('homePage/shareDropMenu/ShareDropMenu').then(({ ShareDropMenu }) => ShareDropMenu),
  {
    ssr: false,
  },
);

export const ClipCard = ({
  clipPageUri,
  fullName,
  subtitle,
  thumbnailUri,
  clipGifUri,
  socialMediaMessage,
  className,
}: ClipCardProps) => {
  return (
    <a href={clipPageUri} className={clsx(classes.card, className)} aria-label={`${fullName}'s video clip`}>
      <div className={classes.imageWrapper}>
        <img
          src={clipGifUri ? clipGifUri : thumbnailUri ?? ''}
          alt={`${fullName}'s clip video thumbnail`}
          className={classes.image}
        />
      </div>
      <div className={classes.content}>
        <div>
          <div className={classes.title}>{fullName}</div>
          <div className={classes.subtitle}>{subtitle}</div>
        </div>
        <div className={classes.share}>
          <ShareDropMenu className={classes.shareDropMenu} url={clipPageUri} socialMediaMessage={socialMediaMessage}>
            <FontAwesomeIcon icon={faShareAlt} />
          </ShareDropMenu>
        </div>
      </div>
    </a>
  );
};
