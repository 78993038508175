import clsx from 'clsx';
import Slider from 'react-slick';

import { PrevArrow } from 'homePage/ceremoniesCarousel/slide/arrows/NaxtArrow';
import { NextArrow } from 'homePage/ceremoniesCarousel/slide/arrows/PrevArrow';
import { Slide } from 'homePage/ceremoniesCarousel/slide/Slide';

import classes from './CeremoniesCarousel.module.scss';
import { CeremoniesCarouselProps } from './CeremoniesCarousel.types';

export const CeremoniesCarousel = ({ ceremonies, color }: CeremoniesCarouselProps) => {
  const settings = {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: <PrevArrow color={color} />,
    nextArrow: <NextArrow color={color} />,
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.divider}>
        <div className={classes.dividerTitle}>Ceremonies</div>
      </div>
      <div className={clsx('row', classes.carousel)}>
        <Slider infinite={false} {...settings}>
          {ceremonies.map(({ fullName, date, ceremonyPageUri, thumbnailUrl }, index) => (
            <div key={`${fullName}${index}`} className="col-4">
              <Slide title={fullName} date={date} image={thumbnailUrl} url={ceremonyPageUri} color={color} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
