import { GetServerSideProps } from 'next';
import Head from 'next/head';
import React from 'react';

import { HomePage } from 'homePage';
import { IsMainUriModel, PortalNextJsModel } from 'models/portal.model';
import { MyGetServerSidePropsContext, ResLocalsProps } from 'server/types';
import { getCeremonies, getPortalProps, SiteLayout } from 'utils/pages';

export type IndexPageProps = {
  data: PortalNextJsModel & IsMainUriModel;
};

const IndexPage = ({
  data: {
    color,
    clientName,
    logoImageUrl,
    mainUri,
    domain,
    addToAnyButtons,
    socialMediaMessage,
    virtualGraduationSectionToggle,
    sectionVirtualGraduation,
    clipSearchEnabled,
    ceremonies,
    institutionId,
    institutionName,
  },
}: IndexPageProps) => {
  const title = `StageClip | ${JSON.stringify(clientName)}`;
  const description = `Shareable video clips from ${clientName}'s events. Share life's big moments with StageClip`;

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={mainUri} />
        <meta property="og:site_name" content="StageClip" />
        <meta property="og:image" content={logoImageUrl} />
        <style>{`:root { --portalColor: ${color} }`}</style>
        <script
          dangerouslySetInnerHTML={{
            __html:
              'var a2a_config = a2a_config || {}; a2a_config.num_services = 6;a2a_config.exclude_services = ["linkedin"];',
          }}
        />
        <script type="text/javascript" src="https://static.addtoany.com/menu/page.js" async defer />
      </Head>
      <HomePage
        portalColor={color || '#fff'}
        domain={domain}
        addToAnyButtons={addToAnyButtons}
        socialMediaMessage={socialMediaMessage}
        virtualGraduationSectionToggle={virtualGraduationSectionToggle}
        sectionVirtualGraduation={sectionVirtualGraduation}
        logoImageUrl={logoImageUrl}
        clipSearchEnabled={clipSearchEnabled}
        ceremonies={ceremonies}
        institutionId={institutionId}
        institutionName={institutionName ? institutionName : ''}
      />
    </>
  );
};

IndexPage.layout = SiteLayout;

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  try {
    const data = await getPortalProps(ctx as MyGetServerSidePropsContext);
    const { protocol } = (ctx as MyGetServerSidePropsContext).res.locals as ResLocalsProps;

    if (!data.isMainUri) {
      ctx.res.writeHead(301, { Location: `${protocol}://${data.mainUri}` }).end();
    }

    const ceremonies = await getCeremonies(ctx as MyGetServerSidePropsContext);

    return { props: { data: { ...data, ceremonies } } };
  } catch {
    ctx.res.writeHead(307, { Location: process.env.NEXT_PUBLIC_STAGECLIP_URL }).end();

    return { props: {} };
  }
};

export default IndexPage;
