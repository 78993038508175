import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import classes from 'homePage/ceremoniesCarousel/CeremoniesCarousel.module.scss';
import { ArrowProps } from 'homePage/ceremoniesCarousel/slide/arrows/Arrows.types';

const KEYBOARD_RIGHT_ARROW_BUTTON_CODE = 39;

export const NextArrow = ({ onClick, color }: ArrowProps) => (
  <div
    role="button"
    tabIndex={0}
    onClick={onClick}
    onKeyDown={(event) => event.keyCode === KEYBOARD_RIGHT_ARROW_BUTTON_CODE && onClick}
    className={clsx(classes.arrow, classes.nextArrow)}
  >
    <div style={{ backgroundColor: color }}>
      <FontAwesomeIcon icon={faArrowRight} inverse />
    </div>
  </div>
);
