import { cacheableApiSurvey } from 'api';
import { SurveyViewModel } from 'models/portal.model';
import { CacheManager } from 'utils/cache.manager';

const fetchSurvey = async (cache: CacheManager, participantId?: string): Promise<SurveyViewModel> =>
  cacheableApiSurvey(cache, `${process.env.NEXT_PUBLIC_API_URL}`, `/portal/survey/participant/${participantId}`);

const fetchSurveyData = {
  fetchSurvey,
};

export default fetchSurveyData;
