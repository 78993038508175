import Observer from '@researchgate/react-intersection-observer';
// eslint-disable-next-line import/no-unresolved
import { ChangeHandler } from '@researchgate/react-intersection-observer/typings/types';
import React, { useState, useEffect } from 'react';

import 'intersection-observer';
import { LazyProps } from 'shared/lazy/Lazy.types';
import { isChildrenPropAFunction } from 'shared/lazy/Lazy.utils';

export const Lazy = ({ children, ...props }: LazyProps) => {
  const [visible, setVisible] = useState(false);

  const onChange: ChangeHandler = ({ isIntersecting }, unobserve) => {
    if (isIntersecting && !visible) {
      unobserve();
      setVisible(true);
    }
  };

  const getChildren = (isVisible: boolean) => (isChildrenPropAFunction(children) ? children(isVisible) : children);

  useEffect(() => {
    if (typeof IntersectionObserver === 'undefined') {
      setVisible(true);
    }
  }, []);

  if (!process.browser) {
    return getChildren(false);
  } else if (visible) {
    return getChildren(true);
  }

  if (typeof IntersectionObserver === 'undefined') {
    return getChildren(false);
  }

  return (
    <Observer {...props} onChange={onChange}>
      {getChildren(false)}
    </Observer>
  );
};
