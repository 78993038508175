const disabledRecentClipsInstitutionIds = process.env.NEXT_PUBLIC_RECENT_CLIPS_DISABLED_INSTITUTIONS;

export const isInstitutionHaveDisableRecentClips = (institutionId: string | null) => {
  if (disabledRecentClipsInstitutionIds) {
    const institutionList = disabledRecentClipsInstitutionIds.split(',');
    const disableRecentClips = institutionList.filter((item) => item === institutionId);

    if (disableRecentClips.length > 0) {
      return true;
    }
  }

  return false;
};
